import styled from 'styled-components';
import Link from 'gatsby-plugin-transition-link';
import Typography from 'components/UI/Typography';
import { h3 } from 'components/UI/Typography/styles';

export const ArticleCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) =>
    props.color === 'blue'
      ? props.theme.colors.blue
      : props.color === 'white'
      ? props.theme.colors.white
      : 'inherit'};

  &.big .ContentWrapperDetails {
    display: block;
    height: auto;
  }

  &.big {
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      .MediaAssetWrapper {
        margin-bottom: 0;
      }
    }
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 48.5466% 45.06%;
      grid-column-gap: 24px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      grid-template-columns: 62.72% 27.194%;
      grid-column-gap: 32px;

      .ContentWrapper {
        margin-top: 46px;
      }
    }
  }

  ol,
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  ul > *:first-of-type,
  ol > *:first-of-type {
    margin-top: 0;
  }
`;

export const MediaAssetWrapper = styled(Link)`
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 100%;

  .big & {
    & .ImageWrapper,
    & .Image {
      height: 100%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      & > div {
        height: 100%;
      }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {

    }
  }

  & .ImageWrapper,
  & .Image {
    height: ${props => props.heightMobile || 'auto'};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    & .ImageWrapper,
    & .Image {
      height: ${props => props.height || 'auto'};
    }
  }

  @media (min-width: 768px) {
    margin-bottom: ${(props) => (props.big ? 0 : '12px')};
  }

  img {
    width: 100%;
    height: auto;
  }

  .Icon-Play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 63px;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > *:not(:last-child) {
    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: ${(props) => (props.big ? '30px' : '12px')};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      margin-bottom: ${(props) =>
        props.big ? '30px' : props.color === 'blue' ? '16px' : '12px'};
    }
  }
`;

export const ContentWrapperDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TagsStyled = styled.ul``;

export const TagStyled = styled.li`
  width: fit-content;
  font-weight: ${(props) => props.theme.fontWeight.small_tag_mobile};
  font-size: ${(props) => props.theme.fonts.small_tag_mobile};
  line-height: ${(props) => props.theme.lineHeight.small_tag_mobile};
  letter-spacing: ${(props) => props.theme.letterSpacing.small_tag_mobile};
  text-transform: uppercase;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    font-weight: 500;
  }

  @media (min-width: 768px) {
    font-size: ${(props) =>
      props.big ? '14px' : props.theme.fonts.small_tag_tablet};
    line-height: ${(props) => props.theme.lineHeight.small_tag_tablet};
    letter-spacing: ${(props) => props.theme.letterSpacing.small_tag_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) => props.theme.fonts.small_tag_desktop};
    line-height: ${(props) => props.theme.lineHeight.small_tag_desktop};
    letter-spacing: ${(props) => props.theme.letterSpacing.small_tag_desktop};
  }

  li& {
    margin: 0;
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      margin: 8px 8px 0 0;

      &:first-of-type {
        margin-top: 8px;
      }
    }
  }
`;

export const TitleStyled = styled(Link)`
  max-width: 251px;
  display: block;
  text-decoration: none;
  color: inherit;
  ${h3};
  line-height: 120%;

  .big & {
    @media (max-width: 767px) {
      font-size: 18px;
    }

    @media (min-width: 768px) {
      max-width: 300px;
    }
  }

  .small & {
    font-size: 18px;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      max-width: 300px;
    }
  }

  .white & {
    @media (max-width: 767px) {
      font-size: 14.4px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      max-width: 251px;
    }
  }
`;

export const BodyStyled = styled(Typography)`
  margin-top: 10px;
  
  @media (min-width: 768px) {
    margin-top: 30px;
  }
`;

export const BottomPanelStyled = styled.div`
  margin-top: 10px;
  display: flex;
  font-weight: ${(props) => props.theme.fontWeight.body_s_mobile};
  font-size: ${(props) => props.theme.fonts.small_tag_mobile};

  @media (min-width: 768px) {
    font-size: ${(props) =>
      props.big
        ? props.theme.fonts.body_s_mobile
        : props.theme.fonts.small_tag_tablet};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: ${(props) =>
      props.color === 'blue'
        ? props.theme.fonts.body_desktop
        : props.theme.fonts.small_tag_tablet};
  }
`;

export const DateStyled = styled(Typography)`
  margin-right: 1em;
`;

export const TimeValue = styled(Typography)`
  margin-left: 3px;
  diplay: inline;
`;

export const ReadingTimeStyled = styled.div`
  position: relative;
  display: flex;
  padding-left: calc(1em + 2px);

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 1.417em;
    background-color: currentColor;
    transform: translateY(-50%);
  }

  .Icon {
    margin-right: 8px;

    .blue & {
      path {
        stroke: ${(props) => props.theme.colors.blue};
      }
    }

    .white & {
      path {
        stroke: ${(props) => props.theme.colors.white};
      }
    }
  }
`;
