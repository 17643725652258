import React, { useEffect, useRef, useState } from 'react';
import { gsap, Power1 } from 'gsap';
import { useInView } from 'react-intersection-observer';
import useWindowSizes from 'utils/hooks/useWindowSizes';
import Button from 'components/UI/Button';
import BackToTop from 'components/UI/BackToTop';
import Container from 'components/Container';
import BlogHead from 'components/BlogHead';
import ArticleCards from 'components/ArticleCards';
import {
  BlogWrapper,
  BlogHeadLinks,
  BlogContent,
  BlogCards,
  ThematicBlock,
  ThematicTitle,
  ThematicBody,
  ThematicBodyContainer,
  ThematicTitleWrapper,
} from './styles';

const ThematicTitleComponent = ({ title }) => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const tl = useRef();

  useEffect(() => {
    tl.current = gsap.timeline();

    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (inView) {
      tl.current.fromTo(
        entry.target,
        { y: '100%' },
        { y: 0, duration: 0.5, ease: Power1.easeOut },
        0
      );
    }
  }, [inView, entry]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <ThematicTitleWrapper ref={ref}>
        <ThematicTitle id="thematic-title">{title}</ThematicTitle>
      </ThematicTitleWrapper>
    </div>
  );
};

const Blog = (props) => {
  const path = props?.path;
  const { edges } = props?.data?.allContentfulArticlePage;
  const { modules } = props?.data?.contentfulBlogPage;

  const toTopName = 'Retour en haut de page';

  const [showButton, setShowButton] = useState(false);

  const contentRef = useRef(null);

  const height = useWindowSizes().windowHeight;
  const mobile = useWindowSizes().windowWidth < 768;

  const [isActive, setIsActive] = useState();

  useEffect(() => {
    const blogHead = contentRef.current.querySelector('#blog-head');
    const tl = gsap
      .timeline()
      .fromTo(
        blogHead,
        { y: '10%', autoAlpha: 0 },
        { y: 0, autoAlpha: 1, duration: 0.5 },
        0.1
      );

    return () => {
      tl.kill();
    };
  }, []);

  useEffect(() => {
    const contentHeight = contentRef?.current?.clientHeight;
    contentHeight > height ? setShowButton(true) : setShowButton(false);
  }, [contentRef, height]);

  const thematicArticles = (check) =>
    edges.filter((ed) => ed?.node?.articleTheme?.title === check);

  const handleGoToClick = (thematic, inx) => {
    setIsActive(inx);
    const anchor = document.getElementById(thematic);

    window.scrollTo({
      top: anchor.offsetTop - 70,
      behavior: 'smooth',
    });
  };

  return (
    <BlogWrapper>
      <Container
        bottomSpacingMobile="30"
        topSpacingTablet="125"
        bottomSpacingTablet="85"
        topSpacingDesktop="150"
        bottomSpacingDesktop="85"
      >
        <BlogContent ref={contentRef}>
          <div id="blog-head">
            {modules && (
              <BlogHeadLinks>
                {modules.map((m, inx) => (
                  <Button
                    key={m?.title}
                    theme="secondary"
                    className={`Button-Thematic ${
                      isActive === inx ? 'is-active' : ''
                    }`}
                    onClick={() => handleGoToClick(m?.title, inx)}
                  >
                    {m?.title}
                  </Button>
                ))}
              </BlogHeadLinks>
            )}
            {edges && <BlogHead card={edges[0].node} path={path} />}
          </div>
          {modules &&
            modules.map((a) => (
              <ThematicBlock key={a.title} id={a.title}>
                {a.title && <ThematicTitleComponent title={a.title} />}
                {a.excerpt && (
                  <ThematicBodyContainer>
                    <ThematicBody theme="body">{a.excerpt}</ThematicBody>
                  </ThematicBodyContainer>
                )}
                {mobile ? (
                  <BlogCards>
                    <ArticleCards
                      articles={thematicArticles(a.title)}
                      slider={false}
                      path={path}
                    />
                  </BlogCards>
                ) : (
                  <ArticleCards
                    articles={thematicArticles(a.title)}
                    slider={true}
                    path={path}
                  />
                )}
              </ThematicBlock>
            ))}
        </BlogContent>
        {showButton && <BackToTop name={toTopName} />}
      </Container>
    </BlogWrapper>
  );
};

export default Blog;
