import styled from 'styled-components';
import Typography from 'components/UI/Typography';
import { h1 } from 'components/UI/Typography/styles';

export const BlogWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 0 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0;
  }
`;

export const BlogHeadLinks = styled.div`
  padding: 10px 0 20px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    border-bottom: 1px solid ${(props) => props.theme.colors.blue};
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    margin: -10px 0 60px;
    padding: 0;
    justify-content: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-left: -10px;
    justify-content: left;
  }

  .Button-Thematic {
    margin: 10px 10px 10px 0;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      margin: 10px;
    }
  }
`;

export const BlogContent = styled.div``;

export const BlogCards = styled.div`
  margin-top: 30px;

  .Article-Card {
    margin-bottom: 30px;
  }
`;

export const ThematicBlock = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.blue};
  padding-top: 40px;

  @media (min-width: 768px) {
    border: none;
    padding-top: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      margin-bottom: 35px;
    }
  }
`;

export const ThematicTitle = styled(Typography)`
  font-size: 17px;
  font-family: ${(props) => props.theme.fontFamily.secondary};
  font-weight: ${(props) => props.theme.fontWeight.h1_mobile};
  line-height: ${(props) => props.theme.lineHeight.h1_mobile};
  color: ${(props) => props.theme.colors.blue};
  text-transform: uppercase;
  transform: translateY('100%');

  @media (min-width: 768px) {
    ${h1};
  }
`;

export const ThematicTitleWrapper = styled.div`
  overflow-y: hidden;
`;

export const ThematicBodyContainer = styled.div`
  margin-top: 20px;
  max-width: 460px;
  color: ${(props) => props.theme.colors.blue};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    max-width: 416px;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
  }
`;

export const ThematicBody = styled(Typography)``;
