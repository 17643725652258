import styled from 'styled-components';
import { p } from 'components/UI/Typography/styles';

export const BackToTopButton = styled.button`
  margin: 0 auto;
  padding: 5px;
  width: fit-content;
  color: ${(props) => props.theme.colors.blue};
  background-color: transparent;
  ${p};

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: 18px;
  }
`;
