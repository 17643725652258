import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import Icon from 'components/Icon';
import ArticleCard from 'components/ArticleCard';
import {
  StyledArticlesSliderWrapper,
  StyledArticlesSliderContainer,
  StyledArticlesSlider,
  StyledPrev,
  StyledNext,
  StyledCardsList,
} from './styles';

const ArticleCards = ({ articles, slider = false, path, color }) => {
  const [beginning, setBeginning] = useState(true);
  const [end, setEnd] = useState(false);

  SwiperCore.use([Navigation, Scrollbar]);

  const SlideStyle = {
    width: 'fit-content',
    height: 'auto',
    display: 'flex',
    overflow: 'hidden',
  };

  return (
    <>
      {slider ? (
        <StyledArticlesSliderWrapper>
          <StyledArticlesSliderContainer>
            <StyledArticlesSlider
              slideVisibleClass={true}
              slidesPerView={2.5}
              spaceBetween={30}
              slidesOffsetAfter={40}
              scrollbar={{ hide: false }}
              onSlideChange={(swiper) => {
                swiper.isBeginning ? setBeginning(true) : setBeginning(false);
                swiper.isEnd ? setEnd(true) : setEnd(false);
              }}
              onTouchMove={(swiper) => {
                swiper.isBeginning ? setBeginning(true) : setBeginning(false);
                swiper.isEnd ? setEnd(true) : setEnd(false);
              }}
              onTouchEnd={(swiper) => {
                swiper.isBeginning ? setBeginning(true) : setBeginning(false);
                swiper.isEnd ? setEnd(true) : setEnd(false);
              }}
              navigation={{
                prevEl: '.Nav-Prev',
                nextEl: '.Nav-Next',
              }}
              freeMode={true}
              breakpoints={{
                1440: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                  slidesOffsetAfter: 150,
                },
                1920: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                  slidesOffsetAfter: 150,
                },
              }}
            >
              {articles &&
                articles.map((a, inx) => (
                  <SwiperSlide key={a.id + `${inx}`} style={SlideStyle}>
                    <ArticleCard color={color ? color : 'blue'} path={path} article={a?.node} />
                  </SwiperSlide>
                ))}
              <StyledPrev
                type="button"
                role="button"
                title="previous"
                aria-label="Go to previous"
                aria-disabled={beginning}
                disabled={beginning}
                className="Nav-Prev"
              >
                <Icon type="left-arrow-articles" />
              </StyledPrev>
              <StyledNext
                type="button"
                role="button"
                title="next"
                aria-label="Go to next"
                aria-disabled={end}
                disabled={end}
                className="Nav-Next"
              >
                <Icon type="right-arrow-articles" />
              </StyledNext>
            </StyledArticlesSlider>
          </StyledArticlesSliderContainer>
        </StyledArticlesSliderWrapper>
      ) : (
        <StyledCardsList>
          {articles &&
            articles.map((a, inx) => (
              <ArticleCard
                key={a.id + `${inx}`}
                color={color ? color : 'blue'}
                path={path}
                article={a?.node}
              />
            ))}
        </StyledCardsList>
      )}
    </>
  );
};

ArticleCards.propTypes = {
  articles: PropTypes.array.isRequired,
  slider: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

export default ArticleCards;
