import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledArticlesSliderWrapper = styled.div`
  position: relative;
  padding: 60px 0 85px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 60px 0 85px;
  }
`;

export const StyledArticlesSliderContainer = styled.div`
  position: relative;
  margin-right: -40px;
  margin-left: -40px;

  & .swiper-slide .swiper-slide {
    width: 430px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-right: -150px;
    margin-left: -150px;
  }

  @media (min-width: 1440px) {
    margin-right: calc((100vw - 1140px) / -2);
    margin-left: calc((100vw - 1140px) / -2);
  }

  .swiper-container {
    padding-bottom: 46px;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      padding-bottom: 60px;
    }
  }

  .swiper-scrollbar {
    left: 50%;
    bottom: 0;
    width: 66.537%;
    height: 5px;
    background-color: ${(props) => props.theme.colors.grey};
    border-radius: 0;
    transform: translateX(-50%);

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      width: 64.306%;
      max-width: 926px;
    }

    .swiper-scrollbar-drag {
      background-color: ${(props) => props.theme.colors.blue};
      border-radius: 0;
    }
  }

  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 80%;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      padding-bottom: 82.79%;
    }

    img {
      height: 100%;
    }
  }
`;

export const StyledArticlesSlider = styled(Swiper)`
  position: unset;
  padding-left: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-left: 150px;
  }

  @media (min-width: 1440px) {
    padding-left: calc((100vw - 1140px) / 2);
  }
`;

export const sliderNavStyles = css`
  position: absolute;
  top: 35%;
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0 : 1)};

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    top: 30%;
    width: 70px;
    height: 70px;
  }

  .Icon {
    height: 100%;
  }
`;

export const StyledPrev = styled.button`
  left: 55px;
  ${sliderNavStyles};
`;

export const StyledNext = styled.button`
  right: 55px;
  ${sliderNavStyles};
`;

export const StyledCardsList = styled.div``;

export const SwiperSlideWrapper = styled.div`
  width: 340px;
`;
